/**
 *
 * These styles are solely for adding [background images] or
 * [background colors] to blocks.
 *
 */

 import bgContact from '../../../../../../site/content/assets/flexiblocks/codit/background-form.svg'

 export default {
   header: {
     bg: '#27A8FF',
     pt: '1rem',
   },
   contact: {
     bg: '#27A8FF',
     backgroundImage: `url(${bgContact})`,
     backgroundRepeat: `no-repeat`,
     backgroundPosition: `center`,
     backgroundSize: 'contain',
     '@media (max-width: 765px)': {
       backgroundSize: 'cover',
       paddingBottom: '3rem',
     },
     '@media (max-width: 625px)': {
        paddingBottom: '8rem',
      },
      '@media (max-width: 485px)': {
        paddingBottom: '11rem',
      }
   },
   section: {
    bg: '#FFFFFF',
    pb: 80,
  },
  coditVideo: {
    backgroundColor: '#F7F8FC',
    padding: '40px 0 45px 0',
    marginBottom: '35px !important',
  },
  formSection: {
    bg: '#FFFFFF',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '@media (min-width: 1000px)': {
        display: 'block',
        textAlign: 'center',
    }
  },
  footerSection: {
    bg: '#FFFFFF',
    p: 0,
  },
   sections: {
     bg: '#FFFFFF',
     pt: '5rem',
     '@media (max-width: 991px)': {
      pt: '2rem'
    }
   },
   whyChooseUs: {
     bg: '#FFFFFF',
     pt: '2rem',
     pb: '5rem'
   },
   simplify: {
     bg: '#FFFFFF',
     pt: '5rem',
     pb: '2rem',
     '@media (max-width: 991px)': {
      pt: '1rem'
    }
   },
   features: {
     bg: '#FFFFFF',
     pt: '1rem',
     paddingBottom: '5rem'
   },
   testimonials: {
     bg: '#FFFFFF',
     pt: '8rem'
   },
   trustUs: {
     bg: '#F7F8FC',
     pt: '5rem',
     pb: '5rem'
   },
   blog: {
     bg: '#FFFFFF',
     pt: '5rem',
     pb: '5rem'
   },
   title: {
    fontSize: 40,
    fontWeight: 600,
    lineHeight: '50px',
    color: '#2A205E',
    textAlign: 'center',
    pt: '1rem',
    paddingBottom: '3rem',
    '@media (max-width: 765px)': {
      fontSize: 30,
      textAlign: 'center',
      padding: '5px'
    },
    '@media (max-width: 450px)': {
      lineHeight: '40px',
      marginBottom: '1rem'
    },
  },
  container: {
    backgroundColor: '#42B3FF',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '50px 0',
    overflow: 'hidden',
    marginBottom: 60,
    '@media (min-width: 700px)': {
        backgroundImage: `url(${bgContact})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'contain',
    },
    '@media (min-width: 1400px)': {
        padding: '80px 0',
        backgroundSize: 'cover',
        backgroundPosition: 'center 10px',
    },
  },
  formCtn: {
    width: '100%',
    maxWidth: 300,
    overflow: 'hidden',
    backgroundColor: '#FFF',
    padding: 20,
    borderRadius: 8,
    '@media (min-width: 700px)': {
        maxWidth: 'auto',
    },
    '@media (min-width: 1000px)': {
        width: 500,
        maxWidth: 500,
    }
  }
}
