import React from 'react'
import Reveal from '@solid-ui-components/Reveal'
import { Box, Text, css } from 'theme-ui'
import ContentButtons from '@solid-ui-components/ContentButtons'

const styles = {
    container: {
        padding: '80px 0 200px 0',
        textAlign: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
    },
    title: {
        fontSize: 37,
        fontWeight: 600,
        lineHeight: '50px',
        color: '#2A205E',
        paddingBottom: '12px',
        '@media (max-width: 765px)': {
          fontSize: 30,
          margin: 'auto'
        },
        '@media (max-width: 450px)': {
          lineHeight: '40px',
          marginBottom: '1rem'
        },
    },
    subTitle: {
        margin: '0px 0 30px 0',
        textAlign: 'center',
        fontSize: 17,
        color: '#2A205E',
        '@media (min-width: 1000px)': {
            fontSize: 18,
        },
    },
}

const Block07 = ({ content }) => {

    const { text, buttons, images } = content;
    const [ titleInfo, subtitleInfo ] = text;
    
    return (
        <Box sx={styles.container} css={css({backgroundImage: `url(${images[0].src.publicURL})`})}>
            <Reveal effect='fadeInDown'>
                <Box sx={styles.contentTitle}>
                    <Text sx={styles.title}>
                        {titleInfo.text}
                    </Text>
                </Box>
                <Box sx={styles.contentText}>
                    <Text sx={styles.subTitle}>
                        {subtitleInfo.text}
                    </Text>
                </Box>
                <Box>
                    <ContentButtons content={buttons} />
                </Box>
            </Reveal>
        </Box>
    );

}

export default Block07