import React from 'react'
import Reveal from '@solid-ui-components/Reveal'
import ContentImages from '@solid-ui-components/ContentImages'
import ContentButtons from '@solid-ui-components/ContentButtons'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'
import { Container, Flex, Box, css, Text } from 'theme-ui'

const styles = {
    title: {
        fontSize: 37,
        fontWeight: 600,
        lineHeight: '50px',
        color: '#2A205E',
        paddingBottom: '12px',
        '@media (max-width: 765px)': {
          fontSize: 30,
          margin: 'auto'
        },
        '@media (max-width: 450px)': {
          lineHeight: '40px',
          marginBottom: '1rem'
        },
    },
    subTitle: {
        maxWidth: 500,
        margin: '30px 0 45px 0',
        textAlign: 'center',
        fontSize: 17,
        '@media (min-width: 1000px)': {
            textAlign: 'left',
            fontSize: 18,
        },
    },
    content: {
        flexDirection: `column`,
        size: `full`,
        alignItems: `center`,
        justifyContent: `center`,
        py: 4
    },
    overlay: {
        size: `full`,
        bg: `omegaDarker`,
        opacity: 0.75,
        position: `absolute`,
        top: 0,
        zIndex: -1
    },
    image: {
        size: `full`,
        position: `absolute`,
        top: 0,
        zIndex: -2
    },
}

const Block02 = ({
    content,
    reverse = false
}) => {

    const { text, images, buttons } = content;
    const [titleInfo] = text;

    return (
        <Container sx={{alignItems: 'center'}}>
            <Flex
                sx={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: [
                        reverse ? 'column' : `column-reverse`,
                        null,
                        null,
                        reverse ? `row-reverse` : `row`
                    ]
                }}
            >
                <Box
                    sx={{
                        flexBasis: [null, null, null, `3/5`],
                        [reverse ? 'ml' : 'mr']: [null, null, null, 5],
                        position: `relative`,
                        textAlign: `center`,
                        maxWidth: 600,
                        mt: 5
                    }}
                >
                    <ContentImages
                        content={{ images: [images[0]] }}
                        loading='eager'
                        reverse={reverse}
                        imagePosition='center'
                    />
                </Box>
                <Box
                    sx={{
                        flexBasis: `2/5`,
                        textAlign: [`left`, 'left', 'center', `left`]
                    }}
                >
                    <Reveal effect='fadeInDown'>
                        <Box sx={styles.contentTitle}>
                            <Text sx={styles.title}>
                                {titleInfo.text}
                            </Text>
                        </Box>
                        <Box sx={styles.contentText}>
                            <Text sx={styles.subTitle}>
                                <span>Aprender a</span>
                                <span style={{fontWeight: 600}}> programar ya no está limitado </span>
                                <span>por la disponibilidad de conectividad o computadoras.</span>
                                <br /><br />
                                <span style={{fontWeight: 600}}> Codit se puede programar desde su tablero de programación </span>
                                <span>o desde una computadora, un celular o una tablet y por</span>
                                <span style={{fontWeight: 600}}> más de un dispositivo a la vez.</span>
                            </Text>
                        </Box>
                    </Reveal>
                    {buttons && (
                        <Reveal
                            effect='fadeInRight'
                            delay={1}
                            css={css({
                                mb: [4, null, null, 0],
                                '@media (max-width: 500px)': {
                                    textAlign: 'center'
                                }
                            })}
                        >
                            {buttons && (
                                <>
                                    <ContentButtons content={buttons} />
                                </>
                            )}
                        </Reveal>
                    )}
                </Box>
            </Flex>
        </Container>
    )

}

export default WithDefaultContent(Block02)
