import React, { useEffect } from 'react'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import { graphql } from 'gatsby'
import theme from './_theme'
import styles from './_styles'
import Seo from '@solid-ui-components/Seo'
import Helmet from 'react-helmet'
import favicon from '../../../../../../site/content/assets/favicon.png'

import { Box } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Header from '@solid-ui-blocks/Educabot/Header/header'
import Footer from '@solid-ui-blocks/Educabot/Footer/Block01'
import { FormContact } from '../../../../../solid-ui/solid-ui-components/src/FormContact/FormContact';
import Block01 from '@solid-ui-blocks/Educabot/Codit/Block01'
import Block02 from '@solid-ui-blocks/Educabot/Codit/Block02'
import Block03 from '@solid-ui-blocks/Educabot/Codit/Block03'
import Block04 from '@solid-ui-blocks/Educabot/Codit/Block04'
import Block05 from '@solid-ui-blocks/Educabot/Codit/Block05'
import Block06 from '@solid-ui-blocks/Educabot/Codit/Block06'
import Block07 from '@solid-ui-blocks/Educabot/Codit/Block07'

const IndexPage = props => {
    const { allBlockContent } = props.data
    const content = normalizeBlockContentNodes(allBlockContent?.nodes)

    const webformData = 'https://webforms.pipedrive.com/f/ccGjGwyjG3NrfuTiNHpQ2pRDozUoGgiaespiE1yrTgaaYducRcKqVlDNzISz9FxJiX'

    useEffect( () => {

        const head = document.head;
        const body = document.body;
        if(!head || !body) return;

        const GTMHeadCode = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-PGZDT3P5');`;

        const GTMHeadElement = document.createElement('script');
        GTMHeadElement.innerHTML = GTMHeadCode;

        const GTMBodyElement = document.createElement('noscript');
        const GTMIframe = document.createElement('iframe');
        GTMIframe.src = 'https://www.googletagmanager.com/ns.html?id=GTM-PGZDT3P5';
        GTMIframe.height = 0;
        GTMIframe.width = 0;
        GTMIframe.style.display = 'none';
        GTMIframe.style.visibility = 'hidden';

        GTMBodyElement.append(GTMIframe);

        head.prepend(GTMHeadElement);
        body.prepend(GTMBodyElement);

    }, [] );

  return (
    <Layout theme={theme} {...props}>
        <Seo title='EDUCABOT' />

        <Helmet>
            <link rel="icon" href={favicon} />
        </Helmet>
        
      <Header content={content['header']} />

      <Box sx={styles.section}>
        <Block01 content={content['codit']} />
      </Box>

      <Box sx={styles.section}>
        <Block02 content={content['coditBoard']} />
      </Box>

      <Box sx={styles.section}>
        <Block03 content={content['coditTeaching']} />
      </Box>

      <Box sx={styles.section}>
        <Block04 content={content['coditEase']} />
      </Box>

      <Box sx={{...styles.section, ...styles.coditVideo}}>
        <Block05 content={content['coditVideo']} />
      </Box>

      <Box sx={styles.section}>
        <Block06 content={content['coditSlide']} />
      </Box>

      <Box sx={styles.container}>
        <Box sx={styles.formCtn}>
            <Box sx={styles.formSection} id="coditForm">
                <FormContact webformData={webformData} />
            </Box>
        </Box>
      </Box>

      <Box sx={styles.footerSection}>
        <Block07 content={content['coditFooter']} />
      </Box>

      <Footer content={content['footer']} />
    </Layout>
  )
}

export const query = graphql`
  query coditLanding {
    allBlockContent(
      filter: { page: { in: ["homepage/educabot", "shared"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`
export default IndexPage