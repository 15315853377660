import React from 'react'
import Reveal from '@solid-ui-components/Reveal'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'
import { Container, Flex, Box, Text } from 'theme-ui'
import Tabs from '@solid-ui-components/AulaMaker/Tabs'
import Screenshot from '@solid-ui-blocks/Educabot/AulaMaker/FeaturesWithPhoto/Block03'

const styles = {
    title: {
        fontSize: 37,
        fontWeight: 600,
        lineHeight: '50px',
        color: '#2A205E',
        paddingBottom: '12px',
        '@media (max-width: 765px)': {
          fontSize: 30,
          margin: 'auto'
        },
        '@media (max-width: 450px)': {
          lineHeight: '40px',
          marginBottom: '1rem'
        },
    },
    subTitle: {
        maxWidth: 500,
        margin: '30px 0 45px 0',
        textAlign: 'center',
        fontSize: 17,
        '@media (min-width: 1000px)': {
            fontSize: 18,
        },
    },
    content: {
        flexDirection: `column`,
        size: `full`,
        alignItems: `center`,
        justifyContent: `center`,
        py: 4
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        '& img': {
            maxHeight: 400,
        }
    },
    contentText: {
        maxWidth: 1000,
        fontSize: 17,
        margin: '15px 0 25px 0',
        textAlign: 'center',
        '@media (min-width: 1000px)': {
            fontSize: 18,
            maxWidth: 935,
            margin: '15px auto 25px auto',
        },
    },
    imgWrapper: {
        display: 'grid !important',
        gridTemplateColumns: '1fr',
        gap: 10,
        maxWidth: 500,
        margin: 'auto',
        '@media (min-width: 1000px)': {
            maxWidth: 'none',
            gridTemplateRows: '275px',
            gridTemplateColumns: '1fr 1.5fr 1fr',
            gap: 20,
        },
    },
    imgCtn: {
        width: '100%',
        overflow: 'hidden',
        'img': {
            width: '100%',
            maxWidth: '100%',
        }
    }
}

const Block05 = ({
    content,
}) => {

    const { text, images } = content;
    const [titleInfo] = text;

    return (
        <Container sx={styles.container}>
            <Flex>
                <Box>
                    <Reveal effect='fadeInDown'>
                        <Box sx={styles.contentTitle}>
                            <Text sx={styles.title}>
                                {titleInfo.text}
                            </Text>
                        </Box>
                        <Box sx={styles.contentText}>
                            <span>Nuestro objetivo es que</span>
                            <span style={{fontWeight: 600}}> aprender robótica y programación </span>
                            <span>para niños y niñas</span>
                            <span style={{fontWeight: 600}}> sea fácil e intuitivo </span>
                            <span>sin frustraciones en el camino. Queremos llevar la cultura STEAM a todas las edades.</span>
                        </Box>
                    </Reveal>

                    <Tabs space={3} variant='dots' position='bottom'>
                        {
                            images.map( imgData => (
                                <Screenshot content={{ text: null, buttons: null, images: [imgData] }} />
                            ) )
                        }
                    </Tabs>
                </Box>
            </Flex>
        </Container>
    )

}

export default WithDefaultContent(Block05)
