import React from 'react'
import Reveal from '@solid-ui-components/Reveal'
import ContentImages from '@solid-ui-components/ContentImages'
import ContentButtons from '@solid-ui-components/ContentButtons'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'
import { Container, Flex, Box, css, Text } from 'theme-ui'

const styles = {
    title: {
        maxWidth: 300,
    },
    subTitle: {
        maxWidth: 500,
        margin: '30px 0 45px 0',
        textAlign: 'center',
        fontSize: 17,
        '@media (min-width: 1000px)': {
            textAlign: 'left',
            fontSize: 18,
        },
    },
    content: {
        flexDirection: `column`,
        size: `full`,
        alignItems: `center`,
        justifyContent: `center`,
        py: 4
    },
    overlay: {
        size: `full`,
        bg: `omegaDarker`,
        opacity: 0.75,
        position: `absolute`,
        top: 0,
        zIndex: -1
    },
    image: {
        size: `full`,
        position: `absolute`,
        top: 0,
        zIndex: -2
    },
}

const Block01 = ({
    content,
    reverse = true
}) => {

    const { images, buttons } = content;

    return (
        <Container sx={{alignItems: 'center'}}>
            <Flex
                sx={{
                    alignItems: 'center',
                    flexDirection: [
                        reverse ? 'column' : `column-reverse`,
                        null,
                        null,
                        reverse ? `row-reverse` : `row`
                    ]
                }}
            >
                <Box
                    sx={{
                        flexBasis: [null, null, null, `3/5`],
                        [reverse ? 'ml' : 'mr']: [null, null, null, 5],
                        position: `relative`,
                        textAlign: `center`,
                        mt: 5,
                        maxWidth: 600,
                    }}
                >
                    <ContentImages
                        content={{ images: [images[1]] }}
                        loading='eager'
                        reverse={reverse}
                        imagePosition='center'
                    />
                </Box>
                <Box
                    sx={{
                        flexBasis: `2/5`,
                        textAlign: [`left`, 'left', 'center', `left`]
                    }}
                >
                    <Reveal effect='fadeInDown'>
                        <Box sx={styles.contentTitle}>
                            <Text sx={styles.title}>
                                <ContentImages
                                    content={{ images: [images[0]] }}
                                    loading='eager'
                                    reverse={reverse}
                                    imagePosition='center'
                                />
                            </Text>
                        </Box>
                        <Box sx={styles.contentText}>
                            <Text sx={styles.subTitle}>
                                <span>El robot para</span>
                                <span style={{ fontWeight: 600 }}> nivel inicial </span>
                                que hace que <br /> programar sea
                                <span style={{ fontWeight: 600 }}> sencillo y divertido.</span>
                            </Text>
                        </Box>
                    </Reveal>
                    {buttons && (
                        <Reveal
                            effect='fadeInRight'
                            delay={1}
                            css={css({
                                mb: [4, null, null, 0],
                                '@media (max-width: 500px)': {
                                    textAlign: 'center'
                                }
                            })}
                        >
                            {buttons && (
                                <>
                                    <ContentButtons content={buttons} />
                                </>
                            )}
                        </Reveal>
                    )}
                </Box>
            </Flex>
        </Container>
    )

}

export default WithDefaultContent(Block01)
