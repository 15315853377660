import React from 'react'
import Reveal from '@solid-ui-components/Reveal'
import ContentButtons from '@solid-ui-components/ContentButtons'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'
import { Container, Flex, Box, css, Text } from 'theme-ui'

const styles = {
    title: {
        fontSize: 37,
        fontWeight: 600,
        lineHeight: '50px',
        color: '#2A205E',
        paddingBottom: '12px',
        '@media (max-width: 765px)': {
          fontSize: 30,
          margin: 'auto'
        },
        '@media (max-width: 450px)': {
          lineHeight: '40px',
          marginBottom: '1rem'
        },
    },
    subTitle: {
        maxWidth: 500,
        margin: '30px 0 45px 0',
        textAlign: 'center',
        fontSize: 17,
        '@media (min-width: 1000px)': {
            fontSize: 18,
            maxWidth: 690,
        },
    },
    content: {
        flexDirection: `column`,
        size: `full`,
        alignItems: `center`,
        justifyContent: `center`,
        py: 4
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
    }
}

const Block05 = ({
    content,
}) => {

    const { text, buttons } = content;
    const [titleInfo, descriptionInfo] = text;

    return (
        <Container sx={styles.container}>
            <Flex>
                <Box>
                    <Reveal effect='fadeInDown'>
                        <Box sx={styles.contentTitle}>
                            <Text sx={styles.title}>
                                {titleInfo.text}
                            </Text>
                        </Box>
                        <Box sx={styles.contentText}>
                            <Text sx={styles.subTitle}>
                                {descriptionInfo.text}
                            </Text>
                        </Box>
                    </Reveal>
                    {buttons && (
                        <Reveal
                            effect='fadeInRight'
                            delay={1}
                            css={css({
                                mb: [4, null, null, 0],
                                '@media (max-width: 500px)': {
                                    textAlign: 'center'
                                }
                            })}
                        >
                            {buttons && (
                                <>
                                    <ContentButtons content={buttons} />
                                </>
                            )}
                        </Reveal>
                    )}
                </Box>
            </Flex>
        </Container>
    )

}

export default WithDefaultContent(Block05)
